import Dispatcher from '../dispatcher';

export default class ClientEntity extends Dispatcher {

    constructor(entity) {
        super();
        this.id = entity.id;
        this.data = entity.data;
        this.x = this.data.position.x || 0;
        this.z = this.data.position.z || 0;
        this.rotation = this.data.rotation || 0;
        this.time = 0;
        this.crashed = false;
    }

    destroy() {

    }

    emit(call, data) {
        this.dispatchEvent('emit', { call: call, data: data });
    }

    receive(call, data) {
        this.time = new Date().getTime();
        if(call === "update") {
            if (this.crashed === false) {
                this.x = data.position.x || this.x;
                this.z = data.position.z || this.z;
                this.rotation = data.rotation || this.rotation;
            }
        }
        if (call === 'crash') {
            this.crashed = true;
        }
        this.dispatchEvent(call, { target: this, data: data });
    }
}