import React from 'react';
import events from '../events';
import MiniMapData from './game/MiniMapData'

import EventsPanel from './game/EventsPanel';
import TrafficPanel from './game/TrafficPanel';
import TriggeredEventPanel from './game/TriggeredEventPanel';
import MissionPanel from './game/MissionPanel';
import EntityManager from '../network/entitymanager';
import SpecialEventPanel from './game/SpecialEventPanel';
import GameStartingPanel from './game/GameStartingPanel';
import RoundClearedPanel from './game/RoundClearedPanel';
import TutorialPanel from './game/TutorialPanel';

import '../css/GamePage.scss';
import '../css/Game/PedestrianMenu.scss';
import '../css/Game/SpecialEventPanel.scss';

class GamePage extends React.Component {

    constructor() {
        super();
        this.canvasRef = React.createRef();

        [
            "handleRemoteConnected", "handleGameStarted", "handleRoundStarted", "handleGamePaused",
            "handleGameUnpaused", "handleMissionsUpdated", "handleEventsUpdated", "roundTimeTick",
            "handlePlayerColorUpdated", "handleReceivedGameData", "handleEventClick",
            "handleRoundShow",
        ].forEach((method) => { this[method] = this[method].bind(this); });

        this.initialized = false;

        this.state = {
            points: 0,
            currentRound: 0,
            actionTimes: {},
            showTypeWindow: false,
            playerColor: "red",
            playerNumber: 1,
            started: false,
            enableLaneButton: true,
            missions: [],
            linkedIds: [],
            roundTime: -1,
            roundNumber: 0,
            roundPause: false,
            special: null,
            paused: false,
            waitForRoundRoutine: false,
        };

        this.cars = []
    }

    render() {
        var backgroundStyle;
        if (this.state.level && this.state.level.name) {
            backgroundStyle = {
                backgroundImage: `url('/game/backgrounds/background-${this.state.level.name.toLowerCase()}.png')`,
                backgroundPosition: `bottom -${MiniMapData[this.state.level.name.toLowerCase()].mapYOffset}vh center`
            };
        }

        let image = `url(/game/player_number_background.png)`;
        if (this.state.playerColor !== "blue") {
            image = `url(/game/player_number_background_${this.state.playerColor}.png)`
        }

        return (
            <div id="game-page">
                <div id="header">
                    <div id="player-icon"></div>
                    <div id="player-name">{this.props.playerName}</div>
                    <div id="player-id" style={{ backgroundImage: image }}>P{this.state.playerNumber}</div>
                </div>
                <div id="game-container" style={backgroundStyle}></div>

                <TrafficPanel socket={this.socket} entityManager={this.entityManager} level={this.state.level} />
                <EventsPanel socket={this.socket} level={this.state.level} positions={this.state.positions} events={this.state.events} spawners={this.state.spawners} linkedIds={this.state.linkedIds} />
                <MissionPanel socket={this.socket} missions={this.state.missions} roundNumber={this.state.roundNumber} roundTime={this.state.roundTime} roomCode={this.props.code} />
                <SpecialEventPanel socket={this.socket} special={this.state.special} playerNumber={this.state.playerNumber} />
                <TriggeredEventPanel socket={this.socket} />
                <RoundClearedPanel socket={this.socket} />
                <TutorialPanel socket={this.socket} gameStarted={this.state.started}></TutorialPanel>

                {this.state.paused ? (<div id="pause-overlay"><p>Game paused...</p></div>) : null}
                {this.state.waitForRoundRoutine ? (<div id="round-routine-active-overlay"><p>Concluding<br />current round.<br />Hold on...</p></div>) : null}

                <GameStartingPanel gameStarted={this.state.started} playerName={this.props.playerName} playerNumber={this.state.playerNumber} playerColor={this.state.playerColor} roomCode={this.props.code} />
            </div>
        );
    };

    componentWillUnmount() {
        clearTimeout(this.missionPanelTimeout);
        clearTimeout(this.roundPauseTimeout);
        clearInterval(this.roundInterval);
    }

    handleActionClicked(action) {
        var time = new Date().getTime();
        this.setState((state, props) => {
            var actionTimes = state.actionTimes || {};
            actionTimes[action] = time;
            var newState = {
                actionTimes: actionTimes,
            }
            return newState;
        });
    }

    handleRemoteConnected(data) {
        if (this.socket.id === data.id) {
            this.setState({
                started: data.gameStarted,
                events: data.events,
                spawners: data.spawners,
                positions: data.positions,
            })
        }
    }

    handleGameStarted(level, events, spawners, positions) {
        this.setState({
            level: level,
            events: events,
            spawners: spawners,
            positions: positions,
            started: true,
        })
    }

    componentDidMount() {
        this.socket = this.props.socket;

        this.entityManager = new EntityManager(this.socket);

        this.socket.on(events.syncGameData, this.handleReceivedGameData);
        this.socket.on(events.eventClick, this.handleEventClick);
        this.socket.on(events.updatePlayerColor, this.handlePlayerColorUpdated);
        this.socket.on(events.pauseGame, this.handleGamePaused);
        this.socket.on(events.unpauseGame, this.handleGameUnpaused);
        this.socket.on(events.roundStarted, this.handleRoundStarted);
        this.socket.on(events.updateMissions, this.handleMissionsUpdated);
        this.socket.on(events.updateEvents, this.handleEventsUpdated);
        this.socket.on(events.roundShow, this.handleRoundShow);

        this.socket.on(events.remoteConnect, this.handleRemoteConnected); // maybe remove this one

        this.socket.emit(events.remoteLoaded, { socketID: this.socket.id });
        this.handleMissionsUpdated(this.props.missionData.missions, this.props.missionData.roundTime, this.props.missionData.roundNumber, this.props.missionData.linkedIds);
    }

    handleReceivedGameData(data) {
        if (!this.initialized) {
            this.initialized = true;
            this.socket.on(events.updateScore, this.handleScoreReceived);
            this.socket.on(events.gameStarted, this.handleGameStarted);
        }

        this.setState({
            level: data.level,
            started: data.gameStarted,
            events: data.events,
            spawners: data.spawners,
            positions: data.positions,
            waitForRoundRoutine: data.roundRoutineInProgress,
        })

        this.entityManager.setEntities(data.entities);
    }

    handleEventClick(eventId, event, player) {
        if (this.state.events && this.state.events[eventId]) {
            this.setState((state, props) => {
                var events = state.events;
                events[eventId] = event;
                return {
                    events: events
                }
            });
        }
        if (this.state.spawners && this.state.spawners[eventId]) {
            this.setState((state, props) => {
                var spawners = state.spawners;
                spawners[eventId] = event;
                return {
                    spawners: spawners
                }
            });
        }
    }

    roundTimeTick() {
        if (this.state.paused) { return; }
        if (this.state.roundPause) { return; }

        this.setState((state, props) => {
            return {
                roundTime: Math.max(0, state.roundTime - 1)
            }
        });
    }

    handleTick(event) {
        this.stage.update(event);
    }

    handlePlayerColorUpdated(event) {
        this.setState((state, props) => {
            return {
                playerColor: event.color,
                playerNumber: event.playerNumber,
                special: event.special,
            };
        });
    }

    handleGamePaused() {
        this.setState({ paused: true });
    }

    handleGameUnpaused() {
        this.setState({ paused: false });
    }

    handleMissionsUpdated(missions, roundTime, roundNumber, linkedIds) {
        if (roundTime > 0) {
            clearInterval(this.roundInterval);
            this.roundInterval = setInterval(this.roundTimeTick, 1000);
        }
        this.setState({
            missions: missions,
            roundTime: roundTime,
            roundNumber: roundNumber,
            linkedIds: linkedIds,
        });
    }

    handleRoundStarted(roundNumber, missions, roundTime, linkedIds) {
        if (roundTime > 0) {
            clearInterval(this.roundInterval);
            this.roundInterval = setInterval(this.roundTimeTick, 1000);
        }

        this.setState({
            roundNumber: roundNumber,
            missions: missions,
            roundTime: roundTime,
            linkedIds: linkedIds,
            waitForRoundRoutine: false,
        });

        this.roundPauseTimeout = setTimeout(() => {
            this.setState({ roundPause: false });
        }, 12000);
    }

    handleEventsUpdated(events, spawners, positions) {
        this.setState({
            events: events,
            roundPause: true,
        })
    }

    handleRoundShow() {
        this.setState({
            roundPause: true,
        });
    }
}

export default GamePage;