import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

let isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
if (isLocalhost === false) {
    Sentry.init({dsn: "https://4342ee82623d46e3986307f429cd3236@o414150.ingest.sentry.io/5340857"});
    Sentry.setTag("source", "remote");
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();