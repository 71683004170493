import React from 'react';
import { CSSTransition } from 'react-transition-group'
import events from '../../events';
import icons from '../../icons';
import Sounds from '../../assets/Sounds';

class SpecialEventPanel extends React.Component {

    constructor(props) {
        super();

        this.state = {
            progress: 0,
            showActivateFeedback: false,
            activatedSpecial: null,
        }

        this.handleEventClicked = this.handleEventClicked.bind(this);
        this.handleActivateSpecial = this.handleActivateSpecial.bind(this);
        this.handleRequestActivateSpecial = this.handleRequestActivateSpecial.bind(this);
    }

    render() {
        let ready = this.state.progress >= 1;

        let special = this.props.special;
        let hasSpecial = special !== null;
        let buttonIcon = hasSpecial ? icons.getSpecialEventButtonIconForType(special.type) : null;

        return (
            <div id="special-event-panel">
                <div id="special-event-bar" className={ready ? "ready" : ""} onClick={this.handleRequestActivateSpecial}>
                    <div id="special-event-container">
                        <div className="progress-bar-container">
                            <div className="progress-bar">
                                <div className="fill" style={{ width: (this.state.progress * 100) + "%" }}></div>
                            </div>
                        </div>
                        <div className={"event-icon" + (ready ? "" : " disabled")} style={{ backgroundImage: (buttonIcon) }}></div>
                    </div>
                </div>
                <CSSTransition classNames="special-event-active-panel" timeout={500} unmountOnExit={true} appear={true} in={this.state.showActivateFeedback}>
                    <SpecialEventActivePanel special={this.state.activatedSpecial} />
                </CSSTransition>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.socket && this.props.socket) {
            this.props.socket.on(events.eventClick, this.handleEventClicked);
            this.props.socket.on(events.activateSpecial, this.handleActivateSpecial);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.activateFeedbackTimeout);
    }

    handleEventClicked(eventId, event, player) {
        if (!player) { return; }
        if (player.playerNumber !== this.props.playerNumber) { return; }
        this.setState({ progress: player.clicks / player.special.threshold });
    }

    handleActivateSpecial(player) {
        this.setState({
            showActivateFeedback: true,
            activatedSpecial: player.special,
        });

        let duration = player.special.type === "" ? 1000 : 5000;
        clearTimeout(this.activateFeedbackTimeout);
        this.activateFeedbackTimeout = setTimeout(() => { this.setState({ showActivateFeedback: false }) }, duration);

        if (player.playerNumber === this.props.playerNumber) {
            this.setState({ progress: 0 });
        }
    }

    handleRequestActivateSpecial() {
        if (this.state.progress < 1) { return; }
        this.props.socket.emit(events.requestActivateSpecial);
        Sounds.getInstance().playButtonClick();
    }
}

class SpecialEventActivePanel extends React.Component {

    constructor(props) {
        super();

        this.state = {
            appear: true,
        }

        this.appearTimeout = setTimeout(() => {
            this.setState({ appear: false });
            Sounds.getInstance().playSpecialEventStart(props.special.type);
        }, 300);
    }

    render() {
        let special = this.props.special;

        return (
            <div id="special-event-active">
                <div id="event-background" className={special.type} style={{ backgroundImage: icons.getSpecialEventBackgroundForType(special.type) }}></div>
                <div id="tv-image" style={{ opacity: special.type === "" ? 0 : 1 }}></div>
                <div id="event-image" className={special.type}>
                    <div id="image-container">
                        <div id="image" className={this.state.appear ? "appear" : special.type} style={{ backgroundImage: icons.getSpecialEventIconForType(special.type) }}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpecialEventPanel;
