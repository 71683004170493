import Dispatcher from '../dispatcher';
import events from '../events';
import ClientEntity from './cliententity';

export default class EntityManager extends Dispatcher {
    constructor(socket) {
        super();
        this.socket = socket;
        this.entities = {};

        this.handleEntityCreated = this.handleEntityCreated.bind(this);
        this.handleEntityDestroyed = this.handleEntityDestroyed.bind(this);
        this.handleEntityEmit = this.handleEntityEmit.bind(this);

        socket.on(events.entityCreated, this.handleEntityCreated);
        socket.on(events.entityDestroyed, this.handleEntityDestroyed);
        socket.on(events.entityEmit, this.handleEntityEmit);
    }

    setEntities(data){
        data.forEach(item => {
            this.handleEntityCreated(item);
        });
    }

    handleEntityCreated(data) {
        let clientEntity;

        clientEntity = new ClientEntity(data);

        this.entities[clientEntity.id] = clientEntity;
        
        clientEntity.addEventListener('emit', function (event) {
            this.socket.emit(events.requestEntityEmit, { entityID: clientEntity.id, call: event.call, data: event.data });
        }.bind(this));

        this.dispatchEvent(EntityManager.ENTITY_CREATED, clientEntity);
    }

    handleEntityDestroyed(event) {
        this.entities[event.entityID].destroy();
        this.dispatchEvent(EntityManager.ENTITY_DESTROYED, event.entityID);
        delete(this.entities[event.entityID]);
    }

    handleEntityEmit(event) {
        var entityID = event.entityID;
        var entity = this.entities[entityID];
        if(entity) {
            entity.receive(event.call, event.data);
        }
    }
}

EntityManager.ENTITY_CREATED = "entityCreated";
EntityManager.ENTITY_DESTROYED = "entityDestroyed";