import React from 'react';
import events from '../../events';
import icons from '../../icons';
import Sounds from '../../assets/Sounds';

const ANIMATION_TIMEOUT_MS = 300;
const CLICK_COOLDOWN_MS = 100;

class SharedEventButton extends React.Component {

    constructor(props) {
        super();

        this.state = {
            disabled: false,
            animateButton: false,
            animateProgress: false,
            localClicks: 0,
            feedbackItems: {},
            previousClick: new Date(),
        }

        this.handleClicked = this.handleClicked.bind(this);
        this.handleEventClicked = this.handleEventClicked.bind(this);
        this.handleSpawnerTriggered = this.handleSpawnerTriggered.bind(this);
    }

    render() {
        if (this.props.event && this.props.eventPosition) {
            var clicks = Math.max(this.state.localClicks || 0, this.props.event.clicks || 0);
            var progress = clicks / this.props.event.threshold;
            return (
                <div style={this.props.style}>
                    <div className={"event-button" + (this.props.event.unlocked ? "" : " locked") + ((this.state.disabled === true || this.props.disabled === true) ? " disabled" : "")} >
                        <div className={"button-container" + (this.state.animateButton ? " wobble" : "") + ` ${this.props.type}`} onClick={this.handleClicked}>
                            <div className={"blink-container" + (this.props.event.unlocked && this.props.blink ? " blink" : "")}>
                                <div className="icon" style={{
                                    backgroundImage: icons.getButtonIconUrlForType(this.props.eventPosition.type)
                                }}></div>
                            </div>
                        </div>
                        <div className={"progress-bar" + (this.state.animateProgress ? " wobble" : "") + (clicks <= 0 ? " hidden" : "")}>
                            <div className="fill" style={{ width: (progress * 100) + "%" }}></div>
                        </div>
                    </div>
                    <div className="event-start-feedback" style={{
                        display: (this.state.disabled === true || this.props.disabled === true) ? "block" : "none",
                        backgroundImage: icons.getButtonIconUrlForType(this.props.eventPosition.type)
                    }}></div>
                    <div className="feedback-items-container">
                        {Object.keys(this.state.feedbackItems).map((key) => {
                            var player = this.state.feedbackItems[key];
                            return (<div key={key} style={player.endStyle} className={`feedback-item ${player.color}`}>P{player.playerNumber}</div>)
                        })}
                    </div>
                </div>
            );
        }
        return null;
    }

    componentDidMount() {
        this.props.socket.on(events.eventClick, this.handleEventClicked);
        this.props.socket.on(events.triggerSpawner, this.handleSpawnerTriggered);
    }

    componentWillUnmount() {
        if (this.props.socket) {
            this.props.socket.off(events.eventClick, this.handleEventClicked);
            this.props.socket.off(events.triggerSpawner, this.handleSpawnerTriggered);
        }
        clearTimeout(this.animateButtonTimeout);
        clearTimeout(this.animateProgressTimeout);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.event) { return; }

        if (prevProps.disabled === true && this.props.disabled === false) {
            this.setState({ disabled: false });
        }

        if (this.props.event.clicks > this.state.localClicks) {
            this.setState({ animateProgress: true, localClicks: this.props.event.clicks });
            clearTimeout(this.animateProgressTimeout);
            this.animateProgressTimeout = setTimeout(() => { this.setState({ animateProgress: false }) }, ANIMATION_TIMEOUT_MS);
        } else if (prevProps.event.clicks > 0 && this.props.event.clicks === 0 && this.state.localClicks !== 0) {
            this.setState({ localClicks: 0 });
            Sounds.getInstance().playSharedEventSpawned();
        }

        if (prevProps.event.threshold !== this.props.event.threshold) {
            if (this.props.event.clicks !== this.state.localClicks) {
                this.setState({ localClicks: this.props.event.clicks });
            }
        }
    }

    handleEventClicked(eventId, event, player) {
        if (!player) { return; }

        if (this.props.eventName !== eventId) { return; }

        var randomKey = Math.round(Math.random() * 999999).toString();
        this.setState((state) => {
            var feedbackItems = Object.assign(state.feedbackItems);

            var randomX = Math.round(Math.random() * 4 - 2);
            var endStyle = {
                transform: `translate(${randomX}rem, -5rem) scale(1, 1) rotate(${randomX * 20}deg)`,
                backgroundImage: `url('/game/icons/icn_feedback_${player.color}.png')`,
                opacity: 0
            }
            player.endStyle = endStyle;

            feedbackItems[randomKey] = player;
            return {
                feedbackItems: feedbackItems
            }
        });
        setTimeout(() => {
            this.setState((state) => {
                var feedbackItems = Object.assign(state.feedbackItems);
                delete feedbackItems[randomKey];
                return {
                    feedbackItems: feedbackItems
                }
            })
        }, 1000);
    }

    handleClicked() {
        if (this.state.disabled !== false || this.props.disabled !== false) { return; }

        var clicks = this.state.localClicks || this.props.event.clicks || 0;
        if (clicks >= this.props.event.threshold) { return; }

        if (this.props.event.unlocked === false) { return; }

        let date = new Date();
        if (date - this.state.previousClick < CLICK_COOLDOWN_MS) { return; }

        this.setState((prevState, props) => {
            var clicks = prevState.localClicks + 1;
            var state = {
                animateButton: true,
                animateProgress: true,
                localClicks: clicks,
                previousClick: date,
            };
            if (clicks >= this.props.event.threshold) {
                state.disabled = true;
            }
            return state;
        })

        clearTimeout(this.animateButtonTimeout);
        clearTimeout(this.animateProgressTimeout);
        this.animateButtonTimeout = setTimeout(() => { this.setState({ animateButton: false }) }, ANIMATION_TIMEOUT_MS);
        this.animateProgressTimeout = setTimeout(() => { this.setState({ animateProgress: false }) }, ANIMATION_TIMEOUT_MS);

        this.props.socket.emit(events.requestEventClick, this.props.eventName);

        Sounds.getInstance().playEventButtonClick(this.props.type);
    }

    handleSpawnerTriggered(key, spawner, position, player) {
        if (key !== this.props.eventName) { return; }
        this.props.event.clicks = 0;
        this.setState({ localClicks: 0 });
        Sounds.getInstance().playSharedEventSpawned();
    }
}

export default SharedEventButton;