const localStorageMuteKey = 'sounds_muted';

class Sounds {

    static getInstance() {
        if (!Sounds.instance) {
            Sounds.instance = new Sounds();
        }
        return Sounds.instance;
    }

    initialize() {
        this.eventButtonClick = new Audio('/audio/ActorStateChangeDown.wav');
        this.spawnerButtonClick = new Audio('/audio/ActorStateChangeUp.wav');

        this.buttonClick = new Audio('/audio/sd_ui_button_01.wav');

        this.sharedEventSpawned = new Audio('/audio/sd_sfx_traffic_direct_go_01.wav');

        this.roundCleared = new Audio('/audio/sd_sfx_mission_timer_end.wav');

        this.specialEventGodzilla = new Audio('/audio/sd_sfx_event_godzilla_roar_02.wav');
        this.specialEventMeteor = new Audio('/audio/sd_sfx_event_meteor_start_stereo_01.wav');

        let localMuteState = localStorage.getItem(localStorageMuteKey);
        this.muted = (localMuteState === 'true') || false;
    }

    loadSounds() {
        this.load(this.eventButtonClick);
        this.load(this.spawnerButtonClick);
        
        this.load(this.buttonClick);
        
        this.load(this.sharedEventSpawned);
        
        this.load(this.roundCleared);
        
        this.load(this.specialEventGodzilla);
        this.load(this.specialEventMeteor);
    }

    load(audio) {
        try {
            audio.volume = 0;
            audio.muted = true;
            audio.load();
        } catch (error) {
            console.log(error);
        }
    }

    play(audio, volume) {
        if (this.muted === true) { return; }
        
        try {
            audio.volume = volume ?? 1;
            audio.muted = false;
            audio.currentTime = 0;
            audio.play();
        } catch (error) {
            console.log(error);
        }
    }

    toggleMute() {
        this.muted = !this.muted;
        localStorage.setItem(localStorageMuteKey, this.muted);
        return this.muted;
    }

    getMute() {
        return this.muted;
    }

    playEventButtonClick(type) {
        let sfx = type === 'event' ? this.eventButtonClick : type === 'spawner' ? this.spawnerButtonClick : null;
        if (sfx === null) { return; }
        this.play(sfx, 0.3);
    }

    playButtonClick() {
        this.play(this.buttonClick, 0.3);
    }

    playSharedEventSpawned() {
        this.play(this.sharedEventSpawned, 0.2);
    }

    playRoundCleared() {
        this.play(this.roundCleared, 0.4);
    }

    playSpecialEventStart(type) {
        let sfx = type === 'godzilla' ? this.specialEventGodzilla : type === 'meteor' ? this.specialEventMeteor : null;
        if (sfx === null) { return; }
        this.play(sfx, 0.4);
    }
}

export default Sounds
