import React from 'react';
import '../css/DisconnectedPage.scss';

class DisconnectedPage extends React.Component {
    render() {
        return (
            <div id="app-disconnected-page">
                <div id="message-container">
                    <div id="message">
                        <h1>Whoops..</h1>
                        <p>Looks like the game ended!</p>
                        <input type="button" onClick={this.props.onClose} value="Okay"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DisconnectedPage;