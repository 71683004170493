module.exports = {
    remoteLoaded: 'remote_loaded',
    syncGameData: 'sync_session_data',
    disconnect: 'disconnect',
    eventClick: 'event_click',

    requestUpdatePlayerName: 'request_update_player_name',
    updatePlayerName: 'update_player_name',

    requestCompleteTutorial: 'request_complete_tutorial',
    completeTutorial: 'complete_tutorial',

    requestSpawnActor: 'request_spawn_actor',
    requestLaunchActor: 'request_launch_actor',
    requestTriggerEvent: 'request_trigger_event',

    spawnActor: 'spawn_actor',
    launchActor: 'launch_actor',
    pedestrianTaunt: 'pedestrian_taunt',
    triggerEvent: 'trigger_event',
    triggerSpawner: 'trigger_spawner',
    requestEventClick: 'request_event_click',
    activateSpecial: 'activate_special',
    requestActivateSpecial: 'request_activate_special',

    requestCreateEntity: 'request_create_entity',
    entityCreated: 'entity_created',
    requestEntityEmit: 'request_entity_emit',
    entityEmit: 'entity_emit',
    requestDestroyEntity: 'request_destroy_entity',
    entityDestroyed: 'entity_destroyed',
    remoteEmit: 'remote_emit',

    requestUpdatePlayerColor: 'request_update_player_color',
    updatePlayerColor: 'update_player_color',

    requestHitByThrowable: 'request_hit_by_throwable',
    hitByThrowable: 'hit_by_throwable',

    remoteConnect: 'remote_connect',
    remoteDisconnect: 'remote_disconnect',

    appConnect: 'app_connect',
    appDisconnect: 'app_disconnect',

    errorRoomNotFound: 'error_room_not_found',
    errorRoomFull: 'error_room_full',

    gameStarted: 'game_started',
    roundStarted: 'round_started',
    gameEnded: 'game_ended',
    pauseGame: 'pause_game',
    unpauseGame: 'unpause_game',

    updateMissions: 'update_missions',
    updateEvents: 'update_events',

    roundShow: 'request_show_score',
    roundShowComplete: 'request_show_score_complete',
}