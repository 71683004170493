module.exports = {
    roundShowShowMissionsDelay: 2500,
    roundShowHideMissionsDelay: 3500,

    roundShowAnimateMissionCheckmarkDelay: 3750,

    roundShowShowNewMissionTextDelay: 4000,
    roundShowHideNewMissionTextDelay: 3500,

    roundStartedHideMissionsDelay: 6500,

    roundStartedStartCountdownDelay: 6700,
}