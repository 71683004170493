import React from 'react';
import MinimapData from './MiniMapData';
import EntityManager from './../../network/entitymanager';
import '../../css/Game/TrafficPanel.scss';
import icons from '../../icons';

class TrafficPanel extends React.Component {

    constructor() {
        super()
        this.panelRef = React.createRef();
        this.state = {
            width: 0,
            height: 0,
            entities: {}
        }
        this.handleEntityCreated = this.handleEntityCreated.bind(this);
        this.handleEntityUpdated = this.handleEntityUpdated.bind(this);
        this.handleEntityDestroyed = this.handleEntityDestroyed.bind(this);
    }

    render() {
        var trafficContent;

        if (this.props.level?.name) {
            var level = this.props.level.name.toLowerCase();
            var scale = this.state.width / MinimapData[level].levelWidthInUnits;
            var center = MinimapData[level].center
            var mapYOffset = MinimapData[level].mapYOffset;
            var s = this;
            trafficContent = Object.keys(this.state.entities).map((key) => {
                var entity = this.state.entities[key];

                var screenCenter = {
                    x: center.x * s.state.width,
                    y: center.y * s.state.height
                }
                var position = {
                    x: screenCenter.x + (scale * entity.x),
                    y: screenCenter.y + (scale * entity.z)
                }

                if (s.props.level.yRotation !== 0) {
                    position = s.rotatePosition(screenCenter.x, screenCenter.y, position.x, position.y, -s.props.level.yRotation);
                }

                return (
                    <ClientEntityView entity={entity} style={{
                        position: "absolute",
                        left: position.x + "px",
                        bottom: position.y + "px",
                        transform: `translateY(${mapYOffset}vh)`,
                    }} key={entity.id} />
                );
            });
        }

        return (
            <div ref={this.panelRef} id="traffic-panel">
                {trafficContent}
            </div>
        )
    }

    componentDidMount() {
        this.setState({
            width: this.panelRef.current.clientWidth,
            height: this.panelRef.current.clientHeight,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.entityManager && this.props.entityManager) {
            this.entityManager = this.props.entityManager;
            this.props.entityManager.addEventListener(EntityManager.ENTITY_CREATED, this.handleEntityCreated);
        }
    }

    componentWillUnmount() {
        clearInterval(this.updateInterval);
        if (this.props.entityManager) {
            this.props.entityManager.removeEventListener(EntityManager.ENTITY_CREATED, this.handleEntityCreated);
        }
    }

    handleEntityCreated(entity) {
        entity.addEventListener("destroy", this.handleEntityDestroyed);
        entity.addEventListener("update", this.handleEntityUpdated);
        this.setState((state) => {
            var entities = Object.assign(state.entities);
            entities[entity.id] = entity;
            return { entities: entities }
        })
    }

    handleEntityDestroyed(event) {
        var entity = event.target;
        entity.removeEventListener("destroy", this.handleEntityDestroyed);
        entity.removeEventListener("update", this.handleEntityUpdated);
        setTimeout(() => {
            this.setState((state) => {
                var entities = Object.assign(state.entities);
                delete entities[entity.id];
                return { entities: entities }
            })
        }, 1000);
    }

    handleEntityUpdated() {
        this.forceUpdate();
    }

    rotatePosition(cx, cy, x, y, angle) {
        var radians = (Math.PI / 180) * angle,
            cos = Math.cos(radians),
            sin = Math.sin(radians),
            nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
            ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
        return { x: nx, y: ny };
    }
}

class ClientEntityView extends React.Component {
    constructor(props) {
        super();
        this.crashIconRotation = Math.round(Math.random() * 180);
    }

    render() {
        let iconStyle = {
            backgroundImage: icons.getTopDownActorIconUrlForType(this.props.entity.data.type),
            display: this.props.entity.crashed === true ? 'none' : 'inherit',
        };

        let iconRotationStyle = {
            transform: `rotate(${this.props.entity.rotation}deg)`,
        }

        let iconCrashRotationStyle = {
            transform: `rotate(${this.crashIconRotation}deg)`,
        }

        return (
            <div className='client-entity' style={this.props.style}>
                <div className="rotation-container" style={iconRotationStyle}>
                    <div className={`icon ${this.props.entity.data.type}`} style={iconStyle}></div>
                </div>
                <div className="rotation-container" style={iconCrashRotationStyle}>
                    <div className="icon crash" style={{ display: this.props.entity.crashed === true ? 'inherit' : 'none' }}></div>
                </div>
            </div>
        )
    }
}

export default TrafficPanel;