function getButtonIconUrlForType(type) {
    switch (type) {
        case 'human':
        case 'pedestrian':
            return `url('/game/icons/icn_pedestrian.png')`;
        case 'zombie':
        case 'manhole':
            return `url('/game/icons/icn_zombie.png')`;
        case 'car':
            return `url('/game/icons/icn_car.png')`;
        case 'truck':
            return `url('/game/icons/icn_truck.png')`;
        case 'bus':
            return `url('/game/icons/icn_bus.png')`;
        case 'ryu':
            return `url('/game/icons/icn_ryu.png')`;
        case 'meteor':
            return `url('/game/icons/icn_meteor.png')`;
        case 'rain_event_cheese':
            return `url('/game/icons/icn_cheese.png')`;
        default:
            return `url('/game/icons/icn_car.png')`;
    }
}

function getTopDownActorIconUrlForType(type) {
    switch (type) {
        case 'human':
        case 'pedestrian':
            return `url('/game/actors/pedestrian.png')`;
        case 'zombie':
            return `url('/game/actors/zombie.png')`;
        case 'truck':
            return `url('/game/actors/truck.png')`;
        case 'bus':
            return `url('/game/actors/bus.png')`;
        case 'scooter':
            return `url('/game/actors/scooter.png')`;
        case 'car':
            return `url('/game/actors/car.png')`;
        default:
            return `url('/game/actors/car.png')`;
    }
}

function getSpecialEventIconForType(type) {
    switch (type) {
        case 'meteor':
            return `url(/game/icons/icn_meteor.png)`;
        case 'godzilla':
            return `url(/game/icons/icn_godzilla.png)`;
        default:
            return `url(/game/icons/icn_multispawn.png)`;
    }
}

function getSpecialEventButtonIconForType(type) {
    switch (type) {
        case 'meteor':
            return `url(/game/buttons/btn_meteor.png)`;
        case 'godzilla':
            return `url(/game/buttons/btn_godzilla.png)`;
        default:
            return `url(/game/buttons/btn_multispawn.png)`;
    }
}

function getSpecialEventBackgroundForType(type) {
    switch (type) {
        case 'meteor':
            return `url(/game/backgrounds/background-meteor.png)`;
        case 'godzilla':
            return `url(/game/backgrounds/background-godzilla.png)`;
        default:
            return '';
    }
}

module.exports = {
    getButtonIconUrlForType,
    getTopDownActorIconUrlForType,
    getSpecialEventIconForType,
    getSpecialEventButtonIconForType,
    getSpecialEventBackgroundForType,
}