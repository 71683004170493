import React from 'react';
import { CSSTransition } from 'react-transition-group'
import '../../css/Game/RoundClearedPanel.scss';
import Sounds from '../../assets/Sounds';
import events from '../../events';
import timings from '../../roundTimings';

class RoundClearedPanel extends React.Component {

    constructor() {
        super();
        this.state = {
            show: false,
            text: "",
            animateText: false,
            counting: false,
        }
        this.handleGameStarted = this.handleGameStarted.bind(this);
        this.handleRoundStarted = this.handleRoundStarted.bind(this);
        this.handleRoundShow = this.handleRoundShow.bind(this);
        this.handleRoundShowComplete = this.handleRoundShowComplete.bind(this);
    }

    render() {
        return (
            <CSSTransition classNames="round-cleared" timeout={300} unmountOnExit={true} appear={true} in={this.state.show}>
                <div id="round-cleared-panel">
                    <div id="round-text-container">
                        <div id="round-cleared-text" className={(this.state.animateText ? "animate" : "") + (this.state.counting ? " counting" : "")}>
                            {this.state.text}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.socket && this.props.socket) {
            this.props.socket.on(events.gameStarted, this.handleGameStarted);
            this.props.socket.on(events.roundStarted, this.handleRoundStarted);
            this.props.socket.on(events.roundShow, this.handleRoundShow);
            this.props.socket.on(events.roundShowComplete, this.handleRoundShowComplete);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.countdownTimeout);
        clearInterval(this.countdownInterval);
        clearTimeout(this.textAnimateTimeout);
        clearTimeout(this.scoreTimeout);

        this.props.socket.off(events.gameStarted, this.handleGameStarted);
        this.props.socket.off(events.roundStarted, this.handleRoundStarted);
        this.props.socket.off(events.roundShow, this.handleRoundShow);
        this.props.socket.off(events.roundShowComplete, this.handleRoundShowComplete);
    }

    handleGameStarted() {
        this.setState({ show: true });
        this.showTextDelayed('New round!', 500);
    }

    handleRoundStarted(roundNumber, missions, roundTime, linkedIds) {
        this.setState({ show: true });
        this.showText('');
        this.countdownTimeout = setTimeout(() => {
            this.startCountdown()
        }, timings.roundStartedStartCountdownDelay);
    }

    handleRoundShow() {
        this.setState({
            show: true,
            counting: false,
        });

        this.showText('Round cleared!');
        Sounds.getInstance().playRoundCleared();
        this.showTextDelayed('', timings.roundShowShowMissionsDelay, () => {
            this.showTextDelayed('New round!', timings.roundShowShowNewMissionTextDelay);
        });
    }

    handleRoundShowComplete() {
        this.showText('');
    }

    startCountdown() {
        let seconds = 5;

        this.setState({ counting: true });

        this.showText(seconds);
        this.countdownInterval = setInterval(() => {
            seconds--;
            if (seconds > 0) {
                this.showText(seconds);
            } else if (seconds === 0) {
                this.showText('Go!');
            } else {
                clearInterval(this.countdownInterval);
                this.setState({ show: false });
            }
        }, 1000);
    }

    showText(text) {
        this.setState({
            text: text,
            animateText: true,
        });
        this.textAnimateTimeout = setTimeout(() => { this.setState({ animateText: false }) }, 500);
    }

    showTextDelayed(text, delayMs, onComplete) {
        setTimeout(() => {
            this.showText(text);
            if (onComplete != null) {
                onComplete();
            }
        }, delayMs);
    }
}

export default RoundClearedPanel;
