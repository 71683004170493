import React from 'react';
import '../../css/Game/GameStartingPanel.scss';

class GameStartingPanel extends React.Component {

    
    render() {
        let image =`url(/game/player_number_background.png)`;
        if(this.props.playerColor !== "blue"){
            image =`url(/game/player_number_background_`+this.props.playerColor+`.png)`
        }

        if (this.props.gameStarted === false) {
            return (
                <div id="game-starting-panel">
                    <div id="top-bar">
                        <div id="player-name">{this.props.playerName}</div>
                    </div>
                    <div id="middle-bar">
                        <div id="code">Code: {this.props.roomCode}</div>
                    </div>
                    <div id="player-number" style={{ backgroundImage: image }}>
                        <div id="player-number-text">P{this.props.playerNumber}</div>
                    </div>
                    <div id="traffic-light"></div>
                    <div id="bottom-bar">Waiting...</div>
                </div>
            )
        }
        return null;
    }
}

export default GameStartingPanel