import React from 'react';
import '../css/LoadingPage.scss';

class LoadingPage extends React.Component {
    render() {
        return (
            <div id="loading-page">
                <div id="loading-bar">
                    <div id="loading-bar-fill" style={{ width: (this.props.progress * 100) + "%" }}></div>
                </div>
            </div>
        );
    }
}

export {
    LoadingPage
}