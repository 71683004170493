class AssetLoader {

	static getInstance() {
		if (!AssetLoader.instance) {
			AssetLoader.instance = new AssetLoader();
		}
		return AssetLoader.instance;
	}

	constructor() {
		this.images = {};
		this.imageLoaded = this.imageLoaded.bind(this);
	}

	loadAssets(progressCallback, completeCallback) {
		this.progressCallback = progressCallback;
		this.completeCallback = completeCallback;
		this.imagesLoaded = 0;

		this.assets = [
			'/game/backgrounds/background-amsterdam_night.png',
			'/game/backgrounds/background-gouda_night.png',
			'/game/backgrounds/background-newyork_night.png',
			'/game/backgrounds/background-paris_night.png',
			'/game/backgrounds/background-tokyo_night.png',

			'/game/backgrounds/background-godzilla.png',
			'/game/backgrounds/background-meteor.png',

			'/game/actors/pedestrian.png',
			'/game/actors/car.png',
			'/game/actors/bus.png',
			'/game/actors/scooter.png',
			'/game/actors/truck.png',
			'/game/actors/zombie.png',

			'/game/buttons/btn_actor.png',
			'/game/buttons/btn_special.png',
			'/game/buttons/btn_godzilla.png',
			'/game/buttons/btn_meteor.png',
			'/game/buttons/btn_multispawn.png',
			'/game/buttons/btn_info.png',
			'/game/buttons/btn_info_down.png',

			'/game/icons/icn_pedestrian.png',
			'/game/icons/icn_car.png',
			'/game/icons/icn_zombie.png',

			'/game/icons/icn_ryu.png',
			'/game/icons/icn_multispawn.png',
			'/game/icons/icn_meteor.png',
			'/game/icons/icn_godzilla.png',
			'/game/icons/icn_cheese.png',

			'/game/icons/icn_feedback_yellow.png',
			'/game/icons/icn_feedback_red.png',
			'/game/icons/icn_feedback_green.png',
			'/game/icons/icn_feedback_blue.png',

			'/game/icons/icn_go.png',
			'/game/icons/icn_go_go_go.png',

			'/game/icons/icn_audio_mute.png',
			'/game/icons/icn_audio_volume.png',
			
			'/game/player_cone.png',
			'/game/player_name_background.png',
			'/game/player_number_background.png',

			'/game/clouds-bottom.png',
			'/game/clouds-pattern.png',
			'/game/clouds.png',

			'/game/score-background.png',
			'/game/score_bar_fill.png',
			'/game/score_label_background.png',

			'/game/special-event-bar-stroke.png',
			'/game/Dennis_Message.png',
			'/game/next_button_wostick.png',
			'/game/UIMultiplayer_Image_Trafficlight.png',
			'/game/WaitingscreenClock.png',
			'/game/crown.png',
			'/game/final-score-background.png',
			'/game/tv.png',
			'/game/arrow.png',

			'/game/tutorial-missions.png',
			'/game/tutorial-buttons.png',
			'/game/tutorial-special.png',
		];

		for (var i = 0; i < this.assets.length; i++) {
			var image = new Image();
			image.src = this.assets[i];
			image.onload = this.imageLoaded;
			this.images[i] = image;
		}
	}

	imageLoaded() {
		this.imagesLoaded++;
		if (this.progressCallback) {
			this.progressCallback(this.imagesLoaded / this.assets.length);
		}
		if (this.imagesLoaded === this.assets.length) {
			if (this.completeCallback) {
				this.completeCallback();
			}
		}
	}
}

export {
	AssetLoader
}