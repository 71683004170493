import React from 'react';
import { CSSTransition } from 'react-transition-group'
import '../../css/Game/TriggeredEventPanel.scss';
import icons from '../../icons';
import events from '../../events';

const SHOW_DURATION_MS = 600;

class TriggeredEventPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            coolingDown: false,
            shownEvent: "",
            eventQueue: [],
        }

        this.handleEventTriggered = this.handleEventTriggered.bind(this);
    }

    render() {
        return (
            <CSSTransition classNames="triggered-event" timeout={SHOW_DURATION_MS} unmountOnExit={true} appear={true} in={this.state.show}>
                <div id="triggered-event-panel">
                    <div id="triggered-event-image" style={{
                        backgroundImage: icons.getButtonIconUrlForType(this.state.shownEvent)
                    }}></div>
                </div>
            </CSSTransition>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.socket && this.props.socket) {
            this.props.socket.on(events.triggerEvent, this.handleEventTriggered);
        }
        if (!this.state.show && !this.state.coolingDown && this.state.eventQueue.length > 0) {
            this.setState({
                show: true,
                coolingDown: true,
                shownEvent: this.state.eventQueue.pop(),
            });
            this.showTimeout = setTimeout(() => {
                this.setState({ show: false });
                this.showTimeout = setTimeout(() => {
                    this.setState({ coolingDown: false });
                }, SHOW_DURATION_MS * 0.4);
            }, SHOW_DURATION_MS);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.showTimeout);
    }

    handleEventTriggered(eventID, event, position) {
        this.setState((state) => {
            let queue = state.eventQueue;
            queue.push(position.type);
            return { eventQueue: queue }
        });
    }
}

export default TriggeredEventPanel