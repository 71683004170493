import React from 'react';
import gsap from 'gsap';
import '../../css/Game/PlayerScorePanel.scss';

class PlayerScorePanel extends React.Component {

    constructor() {
        super();
        this.state = {
            shownScore: 0,
        };
    }

    render() {
        var highest = this.getHighestScore();

        return (
            <div id="score-overview-container">
                <div id="score-overview">
                    <div id="title">{this.props.header}</div>
                    <div id="line"></div>
                    <div id="score-container">
                        {this.props.scoreData.map((scoreData, index) => {
                            var scoreToShow = Math.min(this.state.shownScore, scoreData.score);
                            return <PlayerScoreItem key={index} scoreToShow={scoreToShow} highestScore={highest} scoreData={scoreData} index={index} />
                        })}
                    </div>
                    <div id="line" className="negative"></div>
                    <ul id="dennis-note" style={{ display: this.props.showDennisNote ? "inherit" : "none" }}>
                        <li>You think you're a</li>
                        <li>hot shot now?</li>
                        <li>-Dennis</li>
                    </ul>
                </div>
            </div>
        )
    }

    componentDidMount() {
        var highest = this.getHighestScore();
        var t = { shownScore: 0 };
        gsap.to(t, {
            shownScore: highest,
            duration: 1.5,
            delay: 1.5,
            onUpdate: () => {
                this.setState({ shownScore: t.shownScore })
            },
            onComplete: () => {
                this.props.onScoreRoutineComplete();
            }
        });
    }

    getHighestScore() {
        let highest = 0;
        this.props.scoreData.forEach((data) => {
            highest = Math.max(highest, data.score);
        });
        return highest;
    }
}

class PlayerScoreItem extends React.Component {

    constructor() {
        super();
        this.nameRef = React.createRef();
    }

    render() {
        let scoreData = this.props.scoreData;
        let winner = scoreData.score === this.props.highestScore;
        let height = ((this.props.scoreToShow / this.props.highestScore) * 100) + "%";

        return (
            <div className="score-item">
                <div className="score-bar">
                    <div className="fill" style={{ backgroundColor: `#${scoreData.color}`, height: height }}></div>
                </div>
                <div className={"crown" + (winner ? " winner" : "")} style={{ display: (winner && height === "100%" ? "unset" : "none") }}></div>
                <div className="name" ref={this.nameRef}>{scoreData.name}</div>
                <div className="score">{Math.round(this.props.scoreToShow)}</div>
            </div>
        )
    }

    componentDidMount() {
        let timeout = this.props.index * 0.15 * 1000 + 500;
        this.nameWobbleTimeout = setTimeout(() => {
            this.nameRef.current.className += ' wobble';
        }, timeout);
    }
}

export default PlayerScorePanel
