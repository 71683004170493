import React from 'react';
import '../css/EndPage.scss';
import Sounds from '../assets/Sounds';
import PlayerScorePanel from './game/PlayerScorePanel'

class EndPage extends React.Component {

    constructor() {
        super();

        this.state = {
            showCloseButton: false,
        }

        this.handleCloseClicked = this.handleCloseClicked.bind(this);
        this.handleScoreRoutineComplete = this.handleScoreRoutineComplete.bind(this);
    }

    render() {
        return (
            <div id="end-page">
                <PlayerScorePanel scoreData={this.props.scoreData} header='Final scores' showDennisNote={true} onScoreRoutineComplete={this.handleScoreRoutineComplete}/>
                <div id="bottom-bar">
                    <p>Thanks for playing!</p>
                </div>
                <input id="confirm" className={this.state.showCloseButton ? "visible" : "hidden"} type="button" onClick={this.handleCloseClicked} value="" />
            </div>
        )
    }

    componentWillUnmount() {
        clearTimeout(this.closeButtonTimeout);
    }

    handleScoreRoutineComplete() {
        this.closeButtonTimeout = setTimeout(() => {
            this.setState({ showCloseButton: true })
        }, 1500);
    }

    handleCloseClicked() {
        this.props.onClose();
        Sounds.getInstance().playButtonClick();
    }
}

export default EndPage
