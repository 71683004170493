var data = {
    gouda_night: {
        levelWidthInUnits: 27,
        center: {
            x: 0.5,
            y: 0.12
        },
        mapYOffset: "0"
    },
    paris_night: {
        levelWidthInUnits: 29,
        center: {
            x: 0.5,
            y: 0.15
        },
        mapYOffset: "0"
    },
    amsterdam_night: {
        levelWidthInUnits: 27,
        center: {
            x: 0.5,
            y: 0.115
        },
        mapYOffset: "0"
    },
    tokyo_night: {
        levelWidthInUnits: 27,
        center: {
            x: 0.5,
            y: 0.115
        },
        mapYOffset: "4"
    },
    newyork_night: {
        levelWidthInUnits: 40,
        center: {
            x: 0.5,
            y: 0.18
        },
        mapYOffset: "4"
    }
}

export default data;