import React from 'react';
import { CSSTransition } from 'react-transition-group'
import $ from 'jquery';
import io from 'socket.io-client';
import { AssetLoader } from './assets/AssetLoader';
import Sounds from './assets/Sounds';
import './css/stylesheet.css';
import { LoadingPage } from './modules/LoadingPage';
import LoginPage from './modules/LoginPage';
import GamePage from './modules/GamePage';
import EndPage from './modules/EndPage';
import DisconnectedPage from './modules/DisconnectedPage';
import events from './events';
import './css/App.scss';
import './css/Notifications.scss';

let preLastTouchStartAt = 0;
let lastTouchStartAt = 0;
const touchDelay = 500;

class App extends React.Component {

    constructor() {
        super();
        this.state = {
            loadingProgress: 0,
            loaded: false,
            connected: false,
            totalPlayerCount: 0,
        }

        this.handleLogin = this.handleLogin.bind(this);
        this.handleConnection = this.handleConnection.bind(this);
        this.handleDisconnectOtherRemote = this.handleDisconnectOtherRemote.bind(this);
        this.handleNameFilledIn = this.handleNameFilledIn.bind(this);
        this.handleDisconnect = this.handleDisconnect.bind(this);
        this.handleRoomNotFound = this.handleRoomNotFound.bind(this);
        this.handleRoomFull = this.handleRoomFull.bind(this);
        this.handleAppDisconnect = this.handleAppDisconnect.bind(this);
        this.handleGameEnded = this.handleGameEnded.bind(this);
        this.handleDisconnectedClose = this.handleDisconnectedClose.bind(this);
        this.handleAssetsLoadProgress = this.handleAssetsLoadProgress.bind(this);
        this.handleAssetsLoaded = this.handleAssetsLoaded.bind(this);
        this.handlePlayerNameChanged = this.handlePlayerNameChanged.bind(this);
        this.handleEndPageClose = this.handleEndPageClose.bind(this);
    }

    componentDidMount() {
        let viewheight = $(window).height();
        let viewwidth = $(window).width();
        let viewport = document.querySelector("meta[name=viewport]");

        AssetLoader.getInstance().loadAssets(this.handleAssetsLoadProgress, this.handleAssetsLoaded);
        Sounds.getInstance().initialize();

        this.initializeDocumentListeners();

        if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.setState({ showOrientationMessage: window.matchMedia('(orientation: landscape)').matches });
        }

        viewport.setAttribute("content", `height=${viewheight}, width=${viewwidth}, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=0`);
    }

    initializeDocumentListeners() {
        let clickListener = () => {
            Sounds.getInstance().loadSounds();
            document.removeEventListener('click', clickListener);
        };
        document.addEventListener('click', clickListener);

        // The following listeners are here to prevent the pinch-to-zoom and double-tap-zoom behaviours on ios.
        document.body.addEventListener('touchmove', e => {
            e.preventDefault();
        }, { passive: false });

        document.addEventListener('touchstart', () => {
            preLastTouchStartAt = lastTouchStartAt;
            lastTouchStartAt = +new Date();
        });
        document.addEventListener('touchend', e => {
            const touchEndAt = +new Date();
            if (touchEndAt - preLastTouchStartAt < touchDelay) {
                e.preventDefault();
                e.target.click();
            }
        });

        window.addEventListener('orientationchange', e => {
            let angle = e.target.screen?.orientation?.angle;
            if (angle === undefined) { return; }
            let isLandscape = angle === 90 || angle === 270;
            this.setState({ showOrientationMessage: isLandscape });
        });
    }

    render() {
        var appDisconnected = (
            <DisconnectedPage onClose={this.handleDisconnectedClose} />
        );
        var loadingPage = (
            <LoadingPage progress={this.state.loadingProgress} />
        );
        var loginPage = (
            <LoginPage
                connected={this.state.connected}
                playerName={this.state.name}
                connectError={this.state.connectError}
                onLogin={this.handleLogin}
                onNameFilledIn={this.handleNameFilledIn} />
        );
        var gamePage = (
            <GamePage
                gameMode={this.state.gameMode}
                code={this.state.code}
                playerName={this.state.name}
                socket={this.socket}
                totalPlayerCount={this.state.totalPlayerCount}
                missionData={this.state.missionData} />
        );
        var endPage = (
            <EndPage scoreData={this.state.scoreData} onClose={this.handleEndPageClose} />
        )

        var content = null;
        if (this.state.finished) {
            content = endPage;
        }
        else if (this.state.showAppDisconnected) {
            content = appDisconnected;
        }
        else if (!this.state.loaded) {
            content = loadingPage;
        }
        else if (this.state.connected && this.state.name && !this.state.connectError) {
            content = gamePage;
        }
        else {
            content = loginPage;
        }

        return (
            <div className="App">
                {content}
                <CSSTransition
                    classNames="new-player-message"
                    timeout={500}
                    unmountOnExit
                    in={this.state.showNewPlayer}>
                    <div id="new-player-message"><div id="message">{this.state.newPlayer} joined the game!</div></div>
                </CSSTransition>
                <CSSTransition
                    classNames="orientation-message"
                    timeout={500}
                    unmountOnExit
                    in={this.state.showOrientationMessage}>
                    <div id="orientation-message"><div id="message">Please rotate<br />your device</div></div>
                </CSSTransition>
            </div>
        );
    }

    handleAssetsLoadProgress(progress) {
        this.setState({ loadingProgress: progress });
    }

    handleAssetsLoaded() {
        this.setState({ loaded: true })
    }

    handleLogin(code) {
        this.setState({ code: code, connectError: undefined }, function () {
            this.socket = io(
                {
                    autoConnect: false,
                    query: {
                        type: 'remote',
                        code: code,
                    }
                }
            );

            this.socket.on(events.remoteConnect, this.handleConnection);
            this.socket.on(events.remoteDisconnect, this.handleDisconnectOtherRemote);
            this.socket.on(events.disconnect, this.handleDisconnect);
            this.socket.on(events.updatePlayerName, this.handlePlayerNameChanged);
            this.socket.on(events.appDisconnect, this.handleAppDisconnect);
            this.socket.on(events.errorRoomNotFound, this.handleRoomNotFound);
            this.socket.on(events.errorRoomFull, this.handleRoomFull);
            this.socket.on(events.gameEnded, this.handleGameEnded);

            this.socket.open();
        }.bind(this));
    }

    handleRoomNotFound() {
        this.setState({
            connected: false,
            connectError: "Room not found!"
        });
    }

    handleRoomFull() {
        this.setState({
            connected: false,
            connectError: "Room full!"
        });
    }

    handleConnection(data) {
        if (data.id === this.socket.id) {
            this.setState({
                gameMode: data.gameMode,
                connected: true,
                name: undefined,
                missionData: data.missionData,
            });
        }

        this.setState({
            totalPlayerCount: data.totalPlayerCount,
        });
    }

    handleDisconnectOtherRemote(data) {
        this.setState({
            totalPlayerCount: data.totalPlayerCount,
        });
    }

    handleDisconnect() {
        this.socket.close();

        this.setState({
            connected: false,
            totalPlayerCount: 0,
        });
    }

    handleAppDisconnect() {
        this.setState({
            connected: false,
            showAppDisconnected: this.state.finished ? false : true
        });
    }

    handleDisconnectedClose() {
        this.setState({
            showAppDisconnected: false
        });
    }

    handleGameEnded(scoreData) {
        this.setState({
            scoreData: scoreData,
            finished: true,
        });

        // Close the connection a little later to try to possibly prevent the game 
        // from going straight to the login page instead of showing the endpage.
        setTimeout(() => {
            this.socket.close();
            this.setState({
                connected: false,
                totalPlayerCount: 0,
            });
        }, 1000);
    }

    handleNameFilledIn(name) {
        this.setState({ name: name }, function () {
            this.socket.emit(events.requestUpdatePlayerName, { name: name });
        });
    }

    handlePlayerNameChanged(event) {
        if (event.id !== this.socket.id) {
            var scope = this;
            this.setState({ showNewPlayer: true, newPlayer: event.name }, function () {
                clearTimeout(scope.nameToastTimeout);
                scope.nameToastTimeout = setTimeout(function () { scope.setState({ showNewPlayer: false }); }, 1500);
            });
        }
    }

    handleEndPageClose() {
        this.setState({
            finished: false,
        });
    }
}

export default App;