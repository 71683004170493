import React from 'react';
import '../css/LoginPage.scss';
import Sounds from '../assets/Sounds';

class LoginPage extends React.Component {

    constructor() {
        super();
        this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
        this.handleSubmitNameClicked = this.handleSubmitNameClicked.bind(this);
        this.handleJamCodeChanged = this.handleJamCodeChanged.bind(this);
        this.handlePlayerNameChanged = this.handlePlayerNameChanged.bind(this);
        this.handleMuteClicked = this.handleMuteClicked.bind(this);
        this.state = {
            jamCode: '',
            playerName: ''
        };
    }

    render() {
        var content;
        var loginForm = (
            <div id="login-form">
                <form onSubmit={this.handleSubmitClicked}>
                    <div id="dennis"></div>
                    <label forhtml="jam-code">Jam Code</label>
                    <div className="connectError">{this.props.connectError}</div>
                    <input id="jam-code" type="text" autoCapitalize="off" autoCorrect="off" autoComplete="off" maxLength="4" value={this.state.jamCode}
                        onChange={this.handleJamCodeChanged} placeholder="...." />
                    <input id="submit" type="submit" value="Start" onClick={this.handleSubmitClicked} />
                </form>
            </div>
        );

        var playerNameForm = (
            <div id="name-form">
                <form onSubmit={this.handleSubmitNameClicked}>
                    <div id="dennis"></div>
                    <label forhtml="player-name">Name</label>
                    <div className="connectError">{this.props.connectError}</div>
                    <input id="player-name" type="text" autoCapitalize="off" autoCorrect="off" autoComplete="off" maxLength="10" value={this.state.playerName}
                        onChange={this.handlePlayerNameChanged} placeholder="Name" />
                    <input id="submit" type="submit" value="Start" onClick={this.handleSubmitNameClicked} />
                </form>
            </div>
        );

        if (!this.props.connected) {
            content = loginForm;
        } else {
            content = playerNameForm;
        }

        return (
            <div id="login-page">
                <div id="logo"></div>
                <div id="mute-button" onClick={this.handleMuteClicked}>
                    <div id="icon" className={Sounds.getInstance().getMute() === true ? "muted" : ""}></div>
                </div>
                {content}
            </div>
        );
    }

    handleJamCodeChanged(event) {
        var code = event.target.value;
        if (code.length > 4) { code = code.slice(0, 4); }
        this.setState({ jamCode: code });
    }

    handlePlayerNameChanged(event) {
        var name = event.target.value;
        if (name.length > 7) { name = name.slice(0, 7); }
        name = name.replace(/[^a-zA-Z0-9]/g, "");
        this.setState({ playerName: name })
    }

    handleSubmitClicked(event) {
        this.props.onLogin(this.state.jamCode.toUpperCase());
        event.preventDefault();
        Sounds.getInstance().playButtonClick();
        return false;
    }

    handleSubmitNameClicked(event) {
        this.props.onNameFilledIn(this.state.playerName);
        event.preventDefault();
        Sounds.getInstance().playButtonClick();
        return false;
    }

    handleMuteClicked() {
        Sounds.getInstance().toggleMute();
        Sounds.getInstance().playButtonClick();
        this.forceUpdate();
    }
}

export default LoginPage;