import React from 'react';
import SharedEventButton from './SharedEventButton';
import '../../css/Game/EventsPanel.scss';
import MinimapData from './MiniMapData';

const BUTTON_COOLDOWN = 3000;

class EventsPanel extends React.Component {

    constructor() {
        super()
        this.panelRef = React.createRef();
        this.state = {
            width: 0,
            height: 0,
            playerRotation: 0,
        }
        this.handleEventSpawned = this.handleEventSpawned.bind(this);
        this.handleCameraRotated = this.handleCameraRotated.bind(this);
    }

    render() {
        var eventContent;
        var spawnerContent;

        var player;
        var eventButtons;

        if (this.props.level?.name) {
            var level = this.props.level.name.toLowerCase();
            var scale = this.state.width / MinimapData[level].levelWidthInUnits;
            var center = MinimapData[level].center
            var mapYOffset = MinimapData[level].mapYOffset;
            var linkedIds = this.props.linkedIds || [];
            var s = this;
            var currentTime = new Date().getTime();
            var buttonRenderer = function (parent, type) {
                return Object.keys(parent).map((key, index) => {
                    if (s.props.positions[key] == null) { return null; }

                    var time = s.state[key + "_timestamp"];
                    time = time || 0;
                    var disabled = currentTime - time < BUTTON_COOLDOWN;

                    var screenCenter = {
                        x: center.x * s.state.width,
                        y: center.y * s.state.height
                    }
                    var position = {
                        x: screenCenter.x + (scale * s.props.positions[key].position.x),
                        y: screenCenter.y + (scale * s.props.positions[key].position.z)
                    }

                    if (s.props.level.yRotation !== 0) {
                        position = s.rotatePosition(screenCenter.x, screenCenter.y, position.x, position.y, -s.props.level.yRotation);
                    }

                    return (
                        <SharedEventButton disabled={disabled} style={{
                            position: "absolute",
                            left: position.x + "px",
                            bottom: position.y + "px",
                            transform: `translateY(${mapYOffset}vh)`,
                        }} key={key} eventName={key} socket={s.props.socket} eventPosition={s.props.positions[key]} event={parent[key]} blink={linkedIds.includes(key)} type={type} />
                    );
                });
            }

            if (this.props.events) {
                eventContent = buttonRenderer(this.props.events, 'event');
            }

            if (this.props.spawners) {
                spawnerContent = buttonRenderer(this.props.spawners, 'spawner');
            }

            eventButtons = eventContent.concat(spawnerContent);
            eventButtons.sort((a, b) => (a.props.eventPosition.position.z < b.props.eventPosition.position.z) ? 1 : -1);

            player = (
                <div style={{
                    position: "absolute",
                    left: (center.x * this.state.width) + "px",
                    bottom: `calc(${center.y * this.state.height}px - ${mapYOffset}vh)`,
                    transform: `rotate(${Math.round(this.state.playerRotation)}deg)`
                }} id="player"></div>
            );
        }

        return (
            <div ref={this.panelRef} id="events-panel">
                {player}
                {eventButtons}
            </div>
        )
    }

    componentDidMount() {
        this.updateInterval = setInterval(this.forceUpdate.bind(this), 250);
        this.setState({
            width: this.panelRef.current.clientWidth,
            height: this.panelRef.current.clientHeight,
        })
    }

    componentWillUnmount() {
        clearInterval(this.updateInterval);
        this.socket.off('update_player_rotation', this.handleCameraRotated);
        this.socket.off('trigger_event', this.handleEventSpawned);
        this.socket.off('trigger_spawner', this.handleEventSpawned);
    }

    componentDidUpdate() {
        if (!this.socket && this.props.socket) {
            this.socket = this.props.socket;
            this.socket.on('trigger_event', this.handleEventSpawned);
            this.socket.on('trigger_spawner', this.handleEventSpawned);
            this.socket.on('update_player_rotation', this.handleCameraRotated);
        }
    }

    handleCameraRotated(rotation) {
        this.setState({ playerRotation: rotation });
    }

    handleEventSpawned(eventID, event, position) {
        var state = {};
        state[eventID + '_timestamp'] = new Date().getTime();
        this.setState(state);
    }

    rotatePosition(cx, cy, x, y, angle) {
        var radians = (Math.PI / 180) * angle,
            cos = Math.cos(radians),
            sin = Math.sin(radians),
            nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
            ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
        return { x: nx, y: ny };
    }
}

export default EventsPanel;