import React from 'react';
import '../../css/Game/MissionPanel.scss';
import events from '../../events';
import Sounds from '../../assets/Sounds';
import timings from '../../roundTimings';

class MissionPanel extends React.Component {

    constructor(props) {
        super();

        this.state = {
            animateRoundTime: false,
            showDescriptions: false,
        }

        this.handleInfoClicked = this.handleInfoClicked.bind(this);
        this.handleMuteClicked = this.handleMuteClicked.bind(this);
        this.handleRoundStarted = this.handleRoundStarted.bind(this);
        this.handleRoundShow = this.handleRoundShow.bind(this);
    }

    render() {
        var time;
        if (this.props.roundTime >= 0) {
            var m = Math.floor(this.props.roundTime / 60).toString().padStart(2, "0");
            var s = (this.props.roundTime % 60).toString().padStart(2, "0");
            time = `${m}:${s}`;
        }

        var missions = this.props.missions || [];

        return (
            <div id="mission-panel">
                <div id="click-area" style={{ pointerEvents: this.state.showDescriptions ? "all" : "none" }} onClick={this.handleInfoClicked}></div>
                <div id="info-button" className={(this.state.showDescriptions ? "pressed" : "")} onClick={this.handleInfoClicked}></div>
                <div id="content">
                    <div id="round-info">
                        <div id="round-number">Round {(this.props.roundNumber ?? 0) + 1}</div>
                        <div id="round-time" className={this.state.animateRoundTime ? "animate" : ""}>{time}</div>
                    </div>
                    <div id="mission-content">
                        {missions.map((mission, index) => <MissionItem key={index} index={index} socket={this.props.socket} mission={mission} open={this.state.showDescriptions} onMissionClick={this.handleInfoClicked} />)}
                    </div>
                </div>
                <div id="bottom-bar">
                    <div id="room-code">Code: <span id="code">{this.props.roomCode}</span></div>
                    <div id="mute-button" onClick={this.handleMuteClicked}><div id="icon" className={Sounds.getInstance().getMute() === true ? "muted" : ""}></div></div>
                    <img draggable="false" alt="clouds-bottom" src="/game/clouds-bottom.png" />
                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.roundTime < this.props.roundTime) {
            clearTimeout(this.roundTimeTimeout);
            this.setState({ animateRoundTime: true })
            this.roundTimeTimeout = setTimeout(() => { this.setState({ animateRoundTime: false }) }, 200);
        }
        if (!prevProps.socket && this.props.socket) {
            this.props.socket.on(events.roundStarted, this.handleRoundStarted);
            this.props.socket.on(events.roundShow, this.handleRoundShow);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.roundStartedTimeout);
        clearTimeout(this.animateMissionsTimeout);
        if (this.props.socket) {
            this.props.socket.off(events.roundStarted, this.handleRoundStarted);
            this.props.socket.off(events.roundShow, this.handleRoundShow);
        }
    }

    handleInfoClicked() {
        this.setState({
            showDescriptions: !this.state.showDescriptions
        });
        Sounds.getInstance().playButtonClick();
    }

    handleMuteClicked() {
        Sounds.getInstance().toggleMute();
        Sounds.getInstance().playButtonClick();
        this.forceUpdate();
    }

    handleRoundStarted(roundNumber, missions, roundTime, linkedIds) {
        if (this.state.showDescriptions === false) {
            this.handleInfoClicked();
        }
        this.roundStartedTimeout = setTimeout(() => {
            this.handleInfoClicked();
        }, timings.roundStartedHideMissionsDelay);
    }

    handleRoundShow() {
        if (this.state.showDescriptions === true) {
            this.handleInfoClicked();
        }
        this.animateMissionsTimeout = setTimeout(() => {
            this.handleInfoClicked();
            this.animateMissionsTimeout = setTimeout(() => {
                this.handleInfoClicked();
            }, timings.roundShowHideMissionsDelay);
        }, timings.roundShowShowMissionsDelay);
    }
}

class MissionItem extends React.Component {

    constructor(props) {
        super();

        this.state = {
            animateTarget: false,
            animateCheckmark: false,
        }

        this.checkmarkAnimationDelay = props.index * 750;
        this.handleRoundShow = this.handleRoundShow.bind(this);

        props.socket.on(events.roundShow, this.handleRoundShow);
    }

    render() {
        const mission = this.props.mission;

        let toggleStateClass = this.props.open ? " open" : " close";
        let completedClass = mission.isCompleted ? " completed" : "";

        let description = mission.description.split(' ');
        for (let i = 0; i < description.length; i++) {
            description[i] = description[i].match(/\d/) ? <span key={i} className="number">{description[i]} </span> : `${description[i]} `;
        }

        let missionCount = this.clamp(mission.currentCount, 0, mission.totalCount);

        return (
            <div key={mission.description + mission.title} className={"mission-item" + toggleStateClass} onClick={this.props.onMissionClick}>
                <div className={"mission-title" + completedClass}>{mission.title}</div>
                <div className={"mission-description" + toggleStateClass}>{description}</div>
                <div className={"mission-target" + (this.state.animateTarget ? " animate" : "")}>
                    <div className="progress-bar">
                        <div className="fill" style={{ width: ((missionCount / mission.totalCount) * 100) + "%" }}></div>
                        <div className="progress-text">{missionCount}/{mission.totalCount}</div>
                    </div>
                    <div className={"checkmark-container" + (this.state.animateCheckmark ? " animate" : "")} style={{ animationDelay: `${this.checkmarkAnimationDelay}ms` }}>
                        <span className={"checkmark" + completedClass}></span>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.mission.currentCount !== this.props.mission.currentCount) {
            this.setState({ animateTarget: true });
            setTimeout(() => { this.setState({ animateTarget: false }) }, 200);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.animateCheckmarkTimeout);
        this.props.socket.off(events.roundShow, this.handleRoundShow);
    }

    handleRoundShow() {
        this.animateCheckmarkTimeout = setTimeout(() => {
            this.setState({ animateCheckmark: true });
            this.animateCheckmarkTimeout = setTimeout(() => {
                this.setState({ animateCheckmark: false });
            }, this.checkmarkAnimationDelay + 300);
        }, timings.roundShowAnimateMissionCheckmarkDelay);
    }

    clamp(value, min, max) {
        return value <= min ? min : value >= max ? max : value;
    }
}

export default MissionPanel;
