import React from 'react';
import { CSSTransition } from 'react-transition-group'
import '../../css/Game/TutorialPanel.scss';
import Sounds from '../../assets/Sounds';
import events from '../../events';

class TutorialPanel extends React.Component {

    constructor(props) {
        super();

        this.state = {
            showPanel: false,
            tutorialIndex: -1,
            animateContent: false,
            buttonsInteractable: false,
        };

        this.tutorialStates = ["missions", "buttons", "special", "special-multispawn", "special-godzilla", "special-meteor"];

        this.handleSkipPressed = this.handleSkipPressed.bind(this);
        this.handleContinuePressed = this.handleContinuePressed.bind(this);
        this.incrementTutorialState = this.incrementTutorialState.bind(this);
    }

    render() {
        let tutorialContent;
        switch (this.tutorialStates[this.state.tutorialIndex]) {
            case "missions":
                tutorialContent = (
                    <div id="tutorial-missions">
                        <div id="images">
                            <div className="image"></div>
                            <div className="arrow"></div>
                        </div>
                        <div className="text">Complete missions to advance to the next round!</div>
                    </div>
                );
                break;
            case "buttons":
                tutorialContent = (
                    <div id="tutorial-buttons">
                        <div id="images">
                            <div className="arrow"></div>
                            <div className="image"></div>
                        </div>
                        <div className="text">Tap buttons to spawn stuff in VR!<br />Relevant taps grant you score.</div>
                    </div>
                );
                break;
            case "special":
                tutorialContent = (
                    <div id="tutorial-special">
                        <div className="text">Every tap progresses your special bar. Once filled up, tap it to activate a big event!</div>
                        <div className="arrow"></div>
                        <div className="image"></div>
                    </div>
                );
                break;
            case "special-multispawn":
                tutorialContent = (
                    <div id="tutorial-special-multispawn">
                        <div id="images">
                            <div className="arrow"></div>
                            <div className="image"></div>
                        </div>
                        <div className="text">Activating the multi-spawn will trigger every spawner all at once</div>
                    </div>
                );
                break;
            case "special-godzilla":
                tutorialContent = (
                    <div id="tutorial-special-godzilla">
                        <div id="images">
                            <div className="image"></div>
                            <div className="arrow"></div>
                        </div>
                        <div className="text">Activating the big monster will spawn one in VR</div>
                    </div>
                );
                break;
            case "special-meteor":
                tutorialContent = (
                    <div id="tutorial-special-meteor">
                        <div id="images">
                            <div className="arrow"></div>
                            <div className="image"></div>
                        </div>
                        <div className="text">Activating the meteor will cause one to come crashing down in VR</div>
                    </div>
                );
                break;
            default:
                break;
        }

        let buttonClass = "progression-button" + (this.state.buttonsInteractable ? "" : " disabled");
        let animateClass = this.state.animateContent ? "animate" : "";

        return (
            <CSSTransition classNames="tutorial" timeout={300} unmountOnExit={true} appear={true} in={this.state.showPanel}>
                <div id="tutorial-panel">
                    <div id="tutorial-container" className={animateClass}>
                        <div id="tutorial-content" className={animateClass}>
                            {tutorialContent}
                        </div>
                        <div id="buttons">
                            <div id="skip" className={buttonClass} onClick={this.handleSkipPressed}>
                                <div className="icon"></div>
                            </div>
                            <div id="continue" className={buttonClass} onClick={this.handleContinuePressed}>
                                <div className="icon"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }

    componentDidMount() {
        this.gameStartTimeout = setTimeout(() => {
            this.showTutorial();
        }, 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.animateContentTimeout);
        clearTimeout(this.buttonInteractabilityTimeout);
        clearTimeout(this.gameStartTimeout);
    }

    handleSkipPressed() {
        this.hideTutorial(true);
        Sounds.getInstance().playButtonClick();
    }

    handleContinuePressed() {
        this.incrementTutorialState();
        Sounds.getInstance().playButtonClick();
    }

    showTutorial() {
        this.setState({
            showPanel: true
        });
        this.incrementTutorialState();
    }

    hideTutorial(skipped) {
        this.setState({
            showPanel: false,
            buttonsInteractable: false,
        });
        this.props.socket.emit(events.requestCompleteTutorial, { skipped: skipped });
    }

    incrementTutorialState() {
        if (this.state.tutorialIndex === this.tutorialStates.length - 1) {
            this.hideTutorial(false);
        } else {
            this.setState((state, props) => ({
                tutorialIndex: state.tutorialIndex + 1,
                animateContent: true,
                buttonsInteractable: false,
            }));

            this.animateContentTimeout = setTimeout(() => {
                this.setState({ animateContent: false });
            }, 200);
            this.buttonInteractabilityTimeout = setTimeout(() => {
                this.setState({ buttonsInteractable: true });
            }, 1500);
        }
    }
}

export default TutorialPanel;
